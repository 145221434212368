@import '~assets/styles/utils/mixin';

.app {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-areas: 
    "aside-nav header"
    "aside-nav main";
    grid-template-rows: 76px 1fr;
    grid-template-columns: min-content 1fr;
    grid-gap: 0 $indent-m;
    overflow-y: hidden;
    overflow-x: auto;
}

.main {
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    
    & > * {
        width: 100%;
    }
}