@import '~assets/styles/utils/mixin';

.header {
    width: 100%;
    max-width: $desktop--s;
    grid-area: header;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    z-index: 5;

    & > *:not(:only-child):not(:last-child) {
        margin-right: $indent-m;
    }

    .page-name {
        flex: 1 100%;
    }
}
