@import '~assets/styles/utils/mixin';

.agent-info-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: $indent-m;
    overflow-y: auto;

    &__header {
        max-width: $desktop--s;
        width: 100%;
        position: sticky;
        top: 0;
        display: flex;
        background-color: $main_bg;
        padding-bottom: $indent-xs;
        z-index: 3;

        .header__inputs-block {
            padding: $indent-xs $indent-ml;
            background-color: $white;
            border-radius: $border-rad-s;
        }

        .aside-main {
            .form-btns {
                height: 100%;
            }
        }
    }

    &__body {
        max-width: $desktop--s;
        width: 100%;
        flex: 1 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: $indent-m;

        & > *:not(:only-child):not(:last-child) {
            margin-bottom: $indent-xs;
        }

        .abonent-status {
            width: 100%;
            display: flex;
            align-items: center;
            padding: $indent-m;
            background-color: $white;
            border-radius: $border-rad-s;
            transition: $transition;

            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-xs;
            }

            &--valid {
                color: $success;
                fill: $success;
                background-color: transparentize($success, 0.9);
            }

            &--unvalid {
                color: $main_edo;
                fill: $main_edo;
                background-color: transparentize($main_edo, 0.9);
            }
        }
    }

    .aside-main {
        display: flex;
        flex-direction: row;

        &__wrapper {
            flex: 1 100%;
            flex-direction: row;

            & > *:first-child {
                flex: 1 100%;
            }
        }
    }
}