@import '~assets/styles/utils/mixin';

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__wrapper {
        width: 400px;
        height: fit-content;
        flex-direction: column;
        padding: $indent-l;
        background-color: $white;
        border-radius: $border-rad-s;
        box-shadow: 0px 8px 16px #0000001a;

        & > *:not(:only-child):not(:last-child) {
            margin-bottom: $indent-ml;

        }
    }
}

.MuiButton-root{
    &.btn {
        &.btn--login {
            height: 48px;
            color: $white;
            fill: $white;
            background-color: $main_edo;
        }
    }
}