@import '~assets/styles/utils/mixin';

.form-part {
    position: relative;
    overflow-anchor: none;
    padding: $indent-sm;
    background: $white;
    border-radius: $border-rad-s;
    transition: $transition;

    & > *:not(:only-child):not(:last-child) {
        margin-bottom: $indent-sm;
        transition: $transition;
    }

    & > *:not(.form-part__header) {
        max-height: 100000vh;
        transition: $transition;
        overflow: visible;
    }

    &--hidden {
        & > *:not(:only-child):not(:last-child) {
            margin-bottom: 0;
        }

        & > *:not(.form-part__header) {
            max-height: 0;
            overflow: hidden;
        }
    }

    &--hidden.form-part--with-error {
        border: 1px solid $error;
        background-color: transparentize($error, 0.9);
    }
}
