@import '~assets/styles/utils/mixin';

.hide-thumb {
    display: flex;
    align-items: center;
    padding: $indent-xs $indent-ml;
    cursor: pointer;
    transition: $transition;
    border-radius: $border-rad-s;

    &.focus-visible {
        box-shadow: 0 0 0 0.2rem ($brandFocus);
    }

    & > * {
        fill: $fontBlack600;
        color: $fontBlack600;
        font-weight: 700;
    }

    &__icon {
        transition: $transition;
    }

    &__title {
        overflow: hidden;
        white-space: nowrap;
        transition: $transition;
    }

    &:hover {
        & > * {
            fill: $main_edo;
            color: $main_edo;
        }
    }
}

.aside-nav {
    &--hidden {
        .hide-thumb {
            & > *:not(:only-child):not(:last-child) {
                margin-right: 0;
            }

            &__title {
                width: 0;
            }

            &__icon {
                transform: rotate(180deg);
            }
        }
    }

    &--shown {
        .hide-thumb {
            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-ml;
            }

            &__title {
                width: 100%;
            }
        }
    }
}
