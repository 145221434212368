@import 'utils/mixin';
@import 'utils/reset';
@import 'fonts';

html {
    @include _font-family;
    text-align-last: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $main_bg;
    color: $fontBlack900;
}

svg {
    width: $indent-ml;
    height: $indent-ml;
    padding: calc(#{$indent-s} / 2);
    transition: $transition;
    color: $fontBlack900;
}

.h {
    &--1 {
        @include _h--1;
    }

    &--2 {
        @include _h--2;
    }

    &--3 {
        @include _h--3;
    }

    &--4 {
        @include _h--4;
    }

    &--5 {
        @include _h--5;
    }

    &--6 {
        @include _h--6;
    }

    &--7 {
        @include _h--7;
    }
}

.p {
    &--s {
        @include _text--s;
    }

    &--xs {
        @include _text--xs;
    }

    &--m {
        @include _text--m;
    }
    
    &--l {
        @include _text--l;
    }
}

.copyTextArea {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
}

// MATERIAL UI STYLE

.MuiButton-root {
    &.btn {
        min-height: 40px;
        padding: 0 calc(#{$indent-s} * 5);
        @include _text--xs;
        font-weight: 700;
        line-height: normal;
        text-transform: none;
        color: $main_edo;
        fill: $main_edo;
        background-color: #ffffff00;
        border: 1px solid $main_edo;
        border-radius: $border-rad-s;
        box-shadow: none;

        &:hover {
            background-color: $main_edo--darck;
            box-shadow: none;
            color: $white;
            fill: $white;
        }
    }

    &.Mui-focusVisible {
        box-shadow: 0 0 0 0.2rem ($brandFocus) !important;
    }

    &.Mui-disabled.btn {
        color: $fontBlack300;
        fill: $fontBlack300;
        border-color: $fontBlack300;
        background-color: #ffffff00;
        pointer-events: initial;
        cursor: not-allowed;

        &:hover {
            color: $fontBlack500;
            fill: $fontBlack500;
            border-color: $fontBlack500;
        }
    }
}

.MuiFormControl-root.input-field, .MuiFormControl-root.select-field {
    .MuiInputLabel-root, .MuiInput-input, .MuiFormHelperText-root, .MuiInputAdornment-root > * {
        font-family: 'Manrope';
    }

    .MuiInputLabel-root, .MuiInput-input {
        font-size: 14px;
    }

    .MuiInputLabel-root {
        z-index: 2;
        pointer-events: none;

        &:not(.Mui-error) {
            color: $fontBlack700;

            .MuiFormLabel-asterisk {
                color: $main_edo;
            }
        }
    }

    .MuiInput-input {
        color: $fontBlack800 !important;
    }

    .MuiInput-root {
        z-index: 1;
        input {
            padding: 4px 0 4px;
            box-shadow: 0 0 0 30px $white !important;
            -webkit-box-shadow: 0 0 0 30px $white inset !important;
            -webkit-text-fill-color: $fontBlack800 !important;
        }
        
        &:before {
            border-bottom:1px solid $fontBlack600;
        }

        &:hover {
            &:before {
                border-bottom:2px solid $fontBlack600;
            }
        }
    }

    .MuiSelect-root {
        padding: 4px 0 4px;
    }

    .Mui-disabled {
        cursor:not-allowed;

        &::before {
            border-bottom-style: dashed;
        }

        &:hover {
            &::before {
                border-bottom: 1px dashed $fontBlack600;
            }
        }
    }

    .MuiTypography-root {
        color: $fontBlack800;
    }
}

.MuiFormHelperText-root {
    &.input-error {
        display: flex;
        color: red;
        overflow: hidden;

        span {
            flex: 1 100%;
            font-weight: 300;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.MuiTooltip-popper{
    .MuiTooltip-tooltip {
        font-size: 14px;
        background-color: $fontBlack900;

        .MuiTooltip-arrow {
            &::before {
                background-color: $fontBlack900;
            }
        }
    }
}

.MuiModal-root {
    .MuiBackdrop-root {
        background-color: transparentize($fontBlack900, 0.5);
    }
}

.MuiFormControlLabel-root {
    margin-right: 0 !important;
    margin-left: 0 !important;

    .MuiCheckbox-root {
        &.leaving-route-checkbox {
            padding: 0;
            
            & > svg {
                margin-right: $indent-xs;
                width: 24px;
                height: auto;
                padding: 0;
                fill: $main_edo;
            }

            &:not(.Mui-checked) {
                svg {
                    fill: $fontBlack600;
                }
            }
        }
    }
            
    .MuiFormControlLabel-label {
        @include _text--xs;
        color: $fontBlack600;
    }

    &:hover {
        .MuiTypography-root {
            color: $fontBlack900;
        }

        .MuiButtonBase-root {
            &:not(.Mui-checked) {
                .MuiIconButton-label {
                    svg {
                        fill: $main_edo;
                    }
                }
            }
        }
    }
}

// FOCUS-VISIBLE POLIFIL SETUP

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

.form-field-row {    
    width: 100%;
    display: table;
    table-layout: fixed;

    & > * {
        display: table-cell;
    }

    & > *:nth-child(n):not(:last-child):not(:only-child) {
        padding-right: 20px;
    }
}