@import '~assets/styles/utils/mixin';

.agent {
    display: flex;
    flex-direction: column;
    @include firmBlock;
    transition: $transition;

    & > *:not(:only-child):not(:last-child) {
        transition: $transition;
    }

    & > *:not(.agent-header) {
        max-height: 100vh;
        transition: $transition;
        overflow: visible;
    }

    &--shown {
        & > *:not(:only-child):not(:last-child) {
            margin-bottom: $indent-sm;
        }
    }

    &--hidden {
        & > *:not(:only-child):not(:last-child) {
            margin-bottom: 0;
        }

        & > *:not(.agent-header) {
            max-height: 0;
            overflow: hidden;
        }

        &.agent--connected {
            .MuiAccordion-root {
                &.connections-idedo {
                    border: 0px solid;
                }
            }
        }
    }

    &--hidden.block--with-error {
        border: 1px solid $error;
        background-color: transparentize($error, 0.9);
    }

    &--duplicate {
        background-color: #ffd740;

        .MuiFormControl-root.input-field {
            .MuiInput-root {
                input {
                    box-shadow: 0 0 0 30px #ffd740 !important;
                    -webkit-box-shadow: 0 0 0 30px #ffd740 inset !important;
                }

                &:before {
                    border-bottom: 1px solid $fontBlack700;
                }

                &:hover {
                    &:before {
                        border-bottom: 2px solid $fontBlack700;
                    }
                }
            }

            .MuiInputLabel-root {
                &:not(.Mui-error) {
                    color: $fontBlack700;
                }
            }
        }
    }

    &--connected {
        background-color: transparentize($error, 0.9);
        border-color: $error;
    }
}
