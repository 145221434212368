@import '~assets/styles/utils/mixin';

.final-form {
    width: 100%;
    height: 100%;
    display: flex;
    padding-bottom: $indent-m;
    overflow-y: auto;

    .form-roaming {
        flex: 1 100%;
        display: flex;
        overflow-y: auto;
    }
}