@import '~assets/styles/utils/mixin';

.client-sender {
    .supplier-info {
        display: flex;
        align-items: center;

        & > *:not(:only-child):not(:first-child) {
            margin-left: $indent-sm;
        }

        &__select {
            flex: 1 100%;
        }

        &__description {
            cursor: pointer;
            
            svg {
                fill: $main_edo;
            }
        }
    }
}