@import '~assets/styles/utils/mixin';

.menu {
    flex: 1 100%;
    display: flex;
    flex-direction: column;

    .nav {
        border-radius: $border-rad-s;
        transition: $transition;

        & > *:not(:only-child):not(:last-child) {
            transition: $transition;
        }

        .menu__item {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $indent-sm $indent-m;
            border-radius: $border-rad-s;
            transition: $transition;
            cursor: pointer;

            & > * {
                fill: $fontBlack600;
                color: $fontBlack600;
                font-weight: 700;
            }

            &.focus-visible {
                box-shadow: 0 0 0 0.2rem ($brandFocus);
            }

            &-title {
                overflow: hidden;
                white-space: nowrap;
                transition: $transition;
            }
        }

        &:hover {
            background: $main_edo--darck;

            .menu__item {
                & > * {
                    fill: $white;
                    color: $white;
                }
            }
        }

        &--active {
            background: transparentize($main_edo, 0.9);

            .menu__item {
                & > * {
                    fill: $main_edo;
                    color: $main_edo;
                }
            }
        }
    }
}

.aside-nav {
    .menu {
        .nav {
            &:not(:only-child):not(:first-child) {
                margin-top: $indent-xs;
            }
        }
    }

    &--hidden {
        .menu {
            padding: 0 calc(#{$indent-s} / 2);

            .nav {
                .menu__item {
                    & > *:not(:only-child):not(:last-child) {
                        margin-right: 0;
                    }

                    &-title {
                        width: 0;
                    }
                }

                // &:not(:first-child):not(:last-child).nav--active {
                //     margin: $indent-xs 0;
                // }

                // &:first-child.nav--active {
                //     margin-bottom: $indent-xs;
                // }

                // &:last-child.nav--active {
                //     margin-top: $indent-xs;
                // }
            }
        }
    }

    &--shown {
        .menu {
            padding: 0 $indent-xs;

            .nav {
                .menu__item {
                    & > *:not(:only-child):not(:last-child) {
                        margin-right: $indent-m;
                    }

                    &-title {
                        width: 100%;
                        text-align: left;
                    }
                }

                // &:not(:first-child):not(:last-child).nav--active {
                //     margin: $indent-m 0;
                // }

                // &:first-child.nav--active {
                //     margin-bottom: $indent-m;
                // }

                // &:last-child.nav--active {
                //     margin-top: $indent-m;
                // }
            }
        }
    }
}
