@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('../fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
    font-display: swap;
}
