@import '~assets/styles/utils/mixin';

.toast-messages__item {
    width: 350px;
    display: flex;
    align-items: center;
    padding: $indent-xm $indent-sm $indent-xm $indent-xm;
    color: $white;
    border-radius: $border-rad-m;
    box-shadow: $box-shadow-xs-m;
    pointer-events: visible;
    transition: $transition;

    & > *:not(:only-child):not(:last-child) {
        margin-right: $indent-m;

    }

    &--success {
        background-color: $success;
        animation: animAppearance 10s forwards;
    }
    
    &--error {
        background-color: $error;
        animation: animAppearance 10s forwards;
    }
    
    &--warning {
        background-color: $fontBlack900;
        animation: animAppearance 10s forwards;
    }
}

.toast-messages__left {
    flex: 1 100%;
    flex-direction: column;
    display: flex;

    & > *:not(:only-child):not(:last-child) {
        margin-bottom: $indent-xs;

    }
}

.toast-messages__right {
    .toast-close {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $indent-xs;
        background-color: transparentize($black, 0.9);
        border-radius: $border-rad-l;
        cursor: pointer;
        transition: $transition;

        svg {
            width: 100%;
            height: auto;
            padding: 0;
            fill: $white;
        }

        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }
}

@keyframes animAppearance{
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    5% {
        transform: translateY(0px);
        opacity: 1;
    }
    95% {
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        transform: translateY(-50px);
        opacity: 0;
    }
}