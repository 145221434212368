@import '~assets/styles/utils/mixin';

.upload-zone {
    display: flex;
    flex-direction: column;

    & > *:not(:only-child):not(:last-child) {
        margin-bottom: $indent-ml;
    }

    .dropzone {
        width: 100%;
        min-height:205px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: $indent-ml;
        background-color: $main_edo--light;
        border: 2px dashed $fontBlack500;
        border-radius: $border-rad-m;
        transition: $transition;

        & > *:not(:only-child):not(:last-child) {
            margin-bottom: $indent-ml;
        }

        &__message {
                display: flex;
                flex-direction: column;
                align-items: center;

                & > *:not(:only-child):not(:last-child) {
                    margin-bottom: $indent-xs;
                }

                &-title {
                    span {
                        display: flex;
                        text-align: center;
                        text-align-last: center;
                    }
                }
        }

        &__warnings {
            // max-width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: $indent-xs;
            background-color: $error;
            color: $white;
            border-radius: $border-rad-s;

            & > *:not(:only-child):not(:last-child) {
                margin-bottom: $indent-xs;
            }
        }
    }

    &--accepted {
        .dropzone {
            color: $success;
            background-color: transparentize($success, 0.9);
            border: 2px dashed $success;
        }
    }

    &--rejected {
        .dropzone {
            color: $error;
            background-color: transparentize($error, 0.9);
            border: 2px dashed $error;
        }}
}