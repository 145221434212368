@import '~assets/styles/utils/mixin';

.logo {
    width: 100%;
    min-height: 76px;
    display: flex;
    align-items: center;
    padding: 0 $indent-ml;
    background: $main_edo--darck;
    transition: $transition;

    & > *:not(:only-child):not(:last-child) {
        transition: $transition;
    }

    & > * {
        color: $white;
        font-weight: 500;
    }

    .product-logo {
        svg {
            height: 32px;
            padding: 0;
        }
    }

    .product-name {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        white-space: nowrap;
        transition: $transition;
    }
}

.aside-nav {
    &--hidden {
        .logo {
            & > *:not(:only-child):not(:last-child) {
                margin-right: 0;
            }

            .product-name {
                width: 0;
            }
        }
    }

    &--shown {
        .logo {
            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-xm;
            }

            .product-name {
                width: 100%;
            }
        }
    }
}
