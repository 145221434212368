@import '~assets/styles/utils/mixin';

.error-page {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    padding: 0 124px;

    &-info {
        width: 50%;

        & > *:not(:only-child):not(:last-child):not(:nth-last-child(-n+2)) {
            margin-bottom: $indent-m;
        }

        & > *:last-child {
            margin-top: 48px;
        }

        &__body {
            display: flex;
            flex-direction: column;

            a.focus-visible {
                box-shadow: 0 0 0 0.2rem ($brandFocus);
            }
        }
    }

    &-baner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        pointer-events: none;

        &__left {
            flex: 1 100%;

            svg {
                width: 100%;
                height: auto;
            }
        }

        &__right {
            flex: 2 100%;
            flex-direction: column;
            justify-content: space-between;

            svg {
                width: 100%;
                height: auto;
            }

            & > *:first-child {
                padding-bottom: 200px ;
            }
        }
    }
}

.MuiButton-root{
    &.btn-error {
        height: 42px;
        padding: 0 $indent-sm;
        font-family: 'Manrope';
        font-size: $text-m;
        line-height: calc(#{$text-m} * 1.375);
        font-weight: 500;
        text-transform: none;
        fill: $white;
        border: 1px solid $main_edo;
        border-radius: $border-rad-s;
        box-shadow: none;

        &:hover {
            background-color: $main_edo;
            color: $white;
            fill: $white;
        }
    }

    &.Mui-focusVisible {
        box-shadow: 0 0 0 0.2rem ($brandFocus) !important;
    }

    & .MuiButton-endIcon {
        transform: rotate(90deg);
    }
}