@import '~assets/styles/utils/mixin';

.agent--connected {
    .MuiFormControl-root.input-field {
        .MuiInput-root {
            input {
                box-shadow: 0 0 0 30px #feecec !important;
                -webkit-box-shadow: 0 0 0 30px #feecec inset !important;
            }
        }
    }

    .MuiAccordion-root {
        &.connections-idedo {
            background-color: transparentize($main_edo, 0.9);
            border-radius: $border-rad-l;
            border: 1px solid $main_edo;
            box-shadow: none;
            transition: $transition;
            
            .MuiAccordionSummary-expandIconWrapper {
                svg {
                    padding: $indent-s;
                    fill: $main_edo;
                    transform: rotate(-180deg);
                }
            }

            &::before {
                background-color: #00000000;
            }

            .connections-idedo__header {
                span {
                    color: $main_edo;
                }
            }

            .connections-idedo__body {
                display: flex;
                flex-direction: column;

                & > *:not(:only-child):not(:first-child) {
                    margin-top: $indent-sm;
                }

                span {
                    color: $fontBlack800;
                }

                .idedo-type {
                    display: flex;
                    flex-direction: column;

                    & > *:not(:only-child):not(:first-child) {
                        margin-top: $indent-s;
                    }

                    &__list {
                        display: flex;
                        flex-direction: column;

                        .idedo-elem {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            & > *:not(:only-child):not(:first-child) {
                                margin-top: $indent-s;
                            }

                            .copy-icon{
                                border-radius: $border-rad-s;
                                transition: $transition;
                                cursor: pointer;

                                svg {
                                    fill: $fontBlack800;
                                }

                                &:hover {
                                    svg {
                                        fill: $main_edo;
                                    }
                                }

                                &.focus-visible {
                                    box-shadow: 0 0 0 0.2rem ($brandFocus);
                                }
                            }
                        }
                    }
                }
            }

            &.Mui-expanded {
                margin-top: 0;

                .MuiAccordionSummary-root {
                    min-height: auto;

                    .MuiAccordionSummary-content {
                        margin: 12px 0;
                    }
                }
            }
        }
    }
}