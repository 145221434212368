@import '~assets/styles/utils/mixin';

.aside-nav {
    grid-area: aside-nav;
    display: flex;
    flex-direction: column;
    background: $white;
    transition: $transition;

    & > *:not(:only-child):not(:last-child) {
        margin-bottom: $indent-m;
    }

    &--hidden {
        width: 72px;
    }

    &--shown {
        width: 260px;
    }
}
