@import '~assets/styles/utils/mixin';

.status-info-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: $indent-m;
    overflow-y: auto;

    &__header {
        max-width: $desktop--s;
        width: 100%;
        position: sticky;
        top: 0;
        display: flex;
        background-color: $main_bg;
        padding-bottom: $indent-xs;
        z-index: 3;

        .header__inputs-block {
            padding: $indent-xs $indent-ml;
            background-color: $white;
            border-radius: $border-rad-s;
        }

        .aside-main {
            .form-btns {
                height: 100%;
            }
        }
    }

    &__body {
        max-width: $desktop--s;
        width: 100%;
        flex: 1 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: $indent-m;

        & > *:first-child {
            margin-bottom: $indent-xs;
        }

        .statement-info {
            display: flex;
            flex-direction: column;
            padding: $indent-ml;
            background-color: $white;
            border-radius: $border-rad-s;

            & > *:not(:only-child):not(:last-child) {
                margin-bottom: $indent-m;
            }

            &__idedo, &__ticket, &__date, &__operator {
                display: flex;
                align-items: center;

                & > *:not(:only-child):not(:last-child) {
                    margin-right: $indent-xs;
                }

                .title {
                    white-space: pre;
                }

                .description {
                    flex: 1 100%;
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    span {
                        color: $main_edo;
                    }
                }
            }
        }
    }

    .aside-main {
        display: flex;
        flex-direction: row;

        &__wrapper {
            flex: 1 100%;
            flex-direction: row;

            & > *:first-child {
                flex: 1 100%;
            }
        }
    }
}