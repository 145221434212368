@import '~assets/styles/utils/mixin';

.leaving-route-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .leaving-route-dialog {
        max-width: 450px;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: $indent-ml;
        background-color: $white;
        border-radius: $border-rad-s;
        box-shadow: $box-shadow-xs-m;

        &__header {
            display: flex;
            margin-bottom: $indent-sm;
            
            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-sm;
            }

            svg {
                padding: 0;
                fill: $main_edo;
            }

            span {
                color: $fontBlack900;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            margin-bottom: $indent-ml;

            span {
                color: $fontBlack800;
            }
        }

        &__footer {
            display: flex;
            
            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-m;
            }

            .check-box {
                flex: 1 100%;
                display: flex;
                align-items: center;
            }

            .buttons {
                display: flex;
            
                & > *:not(:only-child):not(:last-child) {
                    margin-right: $indent-xs;
                }
            }
        }
    }
}

.MuiButton-root {
    &.btn {
        &.btn-leaving-route {
            text-transform: none;
            border-radius: $border-rad-s;

            &__accept {
                background-color: $main_edo;
                color: $white;
            }

            &__denied {
                background: rgba(0, 0, 0, 0);
                color: $fontBlack900;
                border-color: rgba(0, 0, 0, 0);

                &:hover {
                    border: 1px solid $main_edo;
                    color: $main_edo;
                    background: rgba(0, 0, 0, 0);
                }
            }
        }
    }
}