@import '~assets/styles/utils/mixin';

$size: 75px;

.progress-pie {
    position: relative;
    overflow: hidden;

    &__percent {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
    }
    
    .pie {
        width: $size;
        height: $size;

        &__background {
            stroke:$fontBlack300;
        }
        
        &__circle {
            stroke:$main_edo;
            transform: rotate(-90deg);
            transform-origin: center;
            transition: $transition;
        }
    }

    &--loaded {
        .pie {
            &__circle {
                stroke:$success;
            }
        }
    }
}
  
