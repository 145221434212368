@import '~assets/styles/utils/mixin';

.form-btns {
    height: $form_header_height;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-rad-s;
    transition: $transition;
    cursor: pointer;

    &.focus-visible {
        box-shadow: 0 0 0 0.2rem ($brandFocus);
    }
    
    & > span, .btn-icon {
        fill: $white;
        color: $white;
        font-weight: 700;
    }

    &:hover {
        background: lighten($main_edo, 5%);
        box-shadow: $box-shadow-xs-m;
    }

    &--fetching {
        pointer-events: none;
    }
}

.form-btns {
    height: 64px;
    display: flex;
    top: 0;
    position: sticky;
    border-radius: $border-rad-s;

    &>*:first-child {
        border-radius: $border-rad-s 0 0 $border-rad-s;
    }

    &>*:last-child {
        border-radius: 0 $border-rad-s $border-rad-s 0;
    }

    &>*:only-child {
        border-radius: $border-rad-s;
    }

    &__chield {
        flex: 1;
        min-width: 64px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $indent-m;
        transition: $transition;
        cursor: pointer;

        &.btn--send.focus-visible {
            box-shadow: 0 0 0 0.2rem ($brandFocus);
            background-color: lighten($main_edo, 5%);
        }

        &.btn--new.focus-visible {
            box-shadow: 0 0 0 0.2rem ($brandFocus);
            background-color: lighten($success, 5%);
        }

        .btn {
            &-name {
                width: 0;
                display: none;
                margin-right: $indent-m;
                transition: $transition;
                overflow: hidden;

                span {
                    color: $white;
                    font-weight: 700;
                    white-space: pre;
                }
            }

            &-icon {
                svg {
                    fill: $white;
                }
            }
        }
    }
        
    .btn {
        &--focus {
            flex: 1 100%;

            .btn-name {
                width: auto;
                display: block;
            }
        }

        &--send {
            background-color: $main-edo;

            &:hover {
                background-color: $main_edo--darck;
            }
        }

        &--new {
            background-color: $success;

            &:hover {
                background-color: lighten($success, 5%);
            }
        }
    }
}