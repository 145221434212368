@import '~assets/styles/utils/mixin';

.agent-header {
    display: flex;
    align-items: center;

    & > *:not(:only-child):not(:last-child) {
        margin-right: $indent-m;
    }

    &__title {
        min-height: 25px;
        flex: 1 100%;
        position: relative;
        overflow: hidden;

        &-wrapper {
            max-width: 100vw;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            white-space: pre;

            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-m;
            }

            & > .connected-label {
                padding: $indent-s $indent-sm;
                border-radius: $border-rad-s;
                background-color: $error;
                color: $white;
            }

            & > .agent-name {
                white-space: pre;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                font-weight: 700;
            }
        }
    }

    &__hidder,
    &__deleter {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: $transition;
        box-sizing: border-box;
        border-radius: $border-rad-s;

        svg {
            height: 100%;
            padding: 0;
            fill: $main_edo;
        }

        &.focus-visible {
            box-shadow: 0 0 0 0.2rem ($brandFocus);
        }

        &:hover {
            transform: scale(1.2);

            svg {
                fill: $main_edo;
            }
        }

        &--disabled {
            cursor: not-allowed;

            svg {
                fill: $fontBlack300;
            }

            &:hover {
                transform: scale(1);

                svg {
                    fill: $fontBlack500;
                }
            }
        }
    }
}
