@import '~assets/styles/utils/mixin';

.steper {
    max-width: $desktop--s;
    width: 100%;
    top: 0;
    position: sticky;
    background-color: $main_bg;
    z-index: 3;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $indent-xs;
        padding: $indent-xm $indent-ml;
        background-color: $white;
        border-radius: $border-rad-s;

        .step {
            display: flex;
            align-items: center;

            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-xs;
            }

            &__icon {
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 14px;
                font-weight: 600;
                background-color: $fontBlack600;
                border-radius: 50%;
                transition: $transition;

                svg {
                    padding: 6px;
                }
            }

            &__title {
                overflow: hidden;
                white-space: nowrap;
                transition: $transition;
            }

            &--ready {
                .step {
                    &__icon {
                        background: transparentize($success, 0.9);
                        fill: $success;
                        color: $success;
                    }
                    
                    &__title {
                        color: $success;
                    }
                }
            }
        
            &--unready {
                .step {
                    &__icon {
                        background: transparentize($fontBlack600, 0.9);
                        fill: $fontBlack600;
                        color: $fontBlack600;
                    }

                    &__title {
                        color: $fontBlack600;
                    }
                }
            }
        
            &--finish {
                .step {
                    &__icon {
                        background: #ffffff00;
                        fill: $main_edo;
                        color: $main_edo;

                        svg {
                            padding: 4px;
                        }
                    }

                    &__title {
                        color: $main_edo;
                    }
                }
            }
        }

        .step-divider {
            flex: 1 100%;
            height: 2px;
            margin: 0 $indent-sm;
            border-radius: $border-rad-s;
            transition: $transition;

            &--unready {
                background-color: transparentize($fontBlack600, 0.9);
            }

            &--ready {
                background: transparentize($success, 0.9);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .steper {
        &__wrapper {
            justify-content: center;

            .step {
                flex: 10;
                flex-direction: column;

                & > *:not(:only-child):not(:last-child) {
                    margin-right: 0;
                    margin-bottom: $indent-xs;
                }

                &__title {
                    text-align: center;
                    text-align-last: center;
                    white-space: normal;
                }
            }

            .step-divider {
                flex: 1;
            }
        }
    }
  }