@import '~assets/styles/utils/mixin';

.form-part__header {
    display: flex;
    align-items: center;

    & > * {
        fill: $main_edo;
    }

    & > *:not(:only-child):not(:last-child) {
        margin-right: $indent-m;
    }

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparentize($main_edo, 0.9);
        border-radius: $border-rad-m;

        svg {
            width: 32px;
            height: 32px;
            padding: 7px;
        }
    }

    &-title {
        flex: 1 100%;
    }

    &-rollup {
        cursor: pointer;
        transition: $transition;
        border-radius: $border-rad-s;

        &.focus-visible {
            box-shadow: 0 0 0 0.2rem ($brandFocus);
        }

        svg {
            padding: $indent-s;
        }

        &:hover {
            transform: scale(1.2);
        }
    }
}

.form-part--hidden {
    .form-part__header {
        &-rollup {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
