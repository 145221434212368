@import './variables';

/* Breackpoint */
@mixin responsive($breakpoint) {
    @if $breakpoint == mobile-s {
        @media (max-width: 319px) {
            @content;
        }
    }
    @if $breakpoint == mobile-s {
        @media (min-width: 320px) and (max-width: 374px) {
            @content;
        }
    }
    @if $breakpoint == mobile-m {
        @media (min-width: 375px) and (max-width: 424px) {
            @content;
        }
    }
    @if $breakpoint == mobile-l {
        @media (min-width: 425px) and (max-width: 767px) {
            @content;
        }
    }
    @if $breakpoint == tablet {
        @media (min-width: 768px) and (max-width: 1023px) {
            @content;
        }
    }
    @if $breakpoint == desktop-s {
        @media (min-width: 1024px) and (max-width: 1365px) {
            @content;
        }
    }
    @if $breakpoint == desktop-m {
        @media (min-width: 1366px) and (max-width: 1599px) {
            @content;
        }
    }
    @if $breakpoint == desktop-ml {
        @media (min-width: 1600px) and (max-width: 1919px) {
            @content;
        }
    }
    @if $breakpoint == desktop-l {
        @media (min-width: 1920px) and (max-width: 2559px) {
            @content;
        }
    }
    @if $breakpoint == desktop-xl {
        @media (max-width: 2560px) {
            @content;
        }
    }
}

@mixin _font-family {
    font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif;
}

/* Headline */
@mixin _h--1 {
    transition: $transition;
    font-size: $headline-1;
    line-height: calc(#{$headline-1} * 1.375);
    font-weight: 700;
}

@mixin _h--2 {
    transition: $transition;
    font-size: $headline-2;
    line-height: calc(#{$headline-2} * 1.375);
    font-weight: 700;
}

@mixin _h--3 {
    transition: $transition;
    font-size: $headline-3;
    line-height: calc(#{$headline-3} * 1.375);
    font-weight: 700;
}

@mixin _h--4 {
    transition: $transition;
    font-size: $headline-4;
    line-height: calc(#{$headline-4} * 1.375);
    font-weight: 700;
}

@mixin _h--5 {
    transition: $transition;
    font-size: $headline-5;
    line-height: calc(#{$headline-5} * 1.375);
    font-weight: 700;
}

@mixin _h--6 {
    transition: $transition;
    font-size: $headline-6;
    line-height: calc(#{$headline-6} * 1.375);
    font-weight: 700;
}

@mixin _h--7 {
    transition: $transition;
    font-size: $headline-7;
    line-height: calc(#{$headline-7} * 1.375);
    font-weight: 700;
}

/* Text */
@mixin _text--s {
    transition: $transition;
    font-size: $text-s;
    line-height: calc(#{$text-xs} * 1.375);
    font-weight: 500;
}

@mixin _text--xs {
    transition: $transition;
    font-size: $text-xs;
    line-height: calc(#{$text-s} * 1.375);
    font-weight: 500;
}

@mixin _text--m {
    transition: $transition;
    font-size: $text-m;
    line-height: calc(#{$text-m} * 1.375);
    font-weight: 500;
}

@mixin _text--l {
    transition: $transition;
    font-size: $text-l;
    line-height: calc(#{$text-l} * 1.375);
    font-weight: 500;
}

@mixin firmBlock {    
    padding: $indent-sm;
    border: 1px solid $main_bg;
    border-radius: $border-rad-s;
}