@import '~assets/styles/utils/mixin';

.aside-main {
    max-width: 292px;
    min-width: 292px;
    z-index: 4;
    
    &__wrapper {
        display: flex;
        flex-direction: column;
        position: sticky;
        padding-left: $indent-m;

        & > *:not(:only-child):not(:last-child) {
            margin-bottom: $indent-xs;
        }
    }
}