@import '~assets/styles/utils/mixin';

.user-profile {
    min-width: 276px;
    margin: 0 $indent-m 0 0;

    &:empty {
        display: none;
    }

    .user-poppup {
        display: flex;
        flex-direction: column;
        position: relative;

        .poppup__body {
            display: flex;
            align-items: center;

            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-m;
            }
            
            .user__name {
                flex: 1 100%;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                & > *:first-child {
                    color: $fontBlack900;
                }

                span {
                    display: flex;
                    justify-content: flex-end;
                    color: $fontBlack600;
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
    
            .user__avatar {
                display: flex;
                align-items: center;
                cursor: pointer;
                border-radius: 20px;

                & > *:not(:only-child) {
                    margin-right: $indent-m;
                }

                .client-image {
                    min-width: 40px;
                    min-height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: $main_edo;

                    svg {
                        fill: $white;
                    }
                }
    
                .arrow {
                    min-width: 10px;
                    min-height: 10px;
                    border-right: 1px solid $fontBlack900;
                    border-bottom: 1px solid $fontBlack900;
                    transform: rotate(45deg);
                    transition: $transition;
                }

                &.focus-visible {
                    box-shadow: 0 0 0 0.2rem ($brandFocus);
                }
            }
        }

        .poppup__footer {
            width: 100%;
            max-height: 100vh;
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            top: 100%;
            margin-top: $indent-m;
            padding: $indent-m;
            background-color: $white;
            border-radius: $border-rad-s;
            box-shadow: 0px 8px 16px #0000001a;
            overflow: visible;
            transition: $transition;

            & > *:not(:only-child):not(:last-child) {
                margin-bottom: $indent-xs;
            }

            &__item {

            }
        }

        &--shown {
            .poppup__body {
                .user__avatar {
                    .arrow {
                        transform: rotate(-135deg);
                    }
                }
            }
        }
        
        &--hidden {
            .poppup__footer {
                max-height: 0;
                overflow: hidden;
                padding: 0 $indent-m;
            }
        }
    }
}