@import '~assets/styles/utils/mixin';

.message-info {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: $indent-m;
    background-color: $fontBlack300;
    border-radius: $border-rad-m;
    overflow: hidden;
    animation: messageInfoAppearance 0.3s forwards;

    & > *:not(:only-child):not(:first-child) {
        margin-top: $indent-sm;
    }

    span {
        z-index: 1;
    }

    .bg-img {
        position: absolute;
        top: calc(100% - 85px);
        left: calc(100% - 75px);
        z-index: 0;

        svg {
            width: 100px;
            height: 100px;
            fill: $fontBlack500;
        }
    }
}

@keyframes messageInfoAppearance{
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}