@import '~assets/styles/utils/mixin';

.form-info {
    display: flex;
    flex-direction: column;
    padding: $indent-sm;
    border-radius: $border-rad-s;
    background-color: $white;

    &__header {
        height: 48px;
        display: flex;
        align-items: center;
        padding: $indent-sm $indent-m;
        border-radius: $border-rad-s;
        color: $main_edo;
        fill: $main_edo;
        background-color: transparentize($main_edo, 0.9);

        & > *:not(:only-child):not(:last-child) {
            margin-right: $indent-m;
        }

        &-title {
            span {
                font-weight: 700;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        color: $fontBlack600;
        fill: $fontBlack600;

        &-elem {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $indent-sm 0 $indent-m $indent-m;


            & > *:not(:only-child):not(:last-child) {
                margin-right: $indent-m;
            }

            span {
                font-weight: 700;
            }
        }

        &-title {
            flex: 1 100%;
            display: flex;
            align-items: center;
        }

        &-counter {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px $indent-xs;
            border-radius: $border-rad-s;
            color: $main_edo;
            background-color: $light_blue;
            transition: $transition;

            &--warning {
                background-color: transparentize($error, 0.9);
                color: $error;
            }
        }
    }
}