@import '~assets/styles/utils/mixin';

.operator-counterpart {
    @include firmBlock;

    & > *:not(:only-child):not(:last-child) {
        margin-bottom: $indent-m;
    }

    .operator-agreement-blanck {
        display: flex;
        justify-content: center;

        & > * {
            color: $main_edo;

            svg {
                fill: $main_edo;
                transform: $transition;
            }
        }

        & > *:not(:only-child):not(:last-child) {
            margin-right: $indent-m;
        }

        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }
}