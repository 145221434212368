@import '~assets/styles/utils/mixin';

.block-part-content {
    max-width: $desktop--s;
    width: 100%;
    display: flex;
    border-radius: $border-rad-s;

    .main-content {
        flex: 1 100%;
        overflow-y: hidden;
        
        & > *:not(:only-child):not(:last-child) {
            margin-bottom: $indent-xs;
        }
    }

}