@import '~assets/styles/utils/mixin';

.agent-info-table, .status-info-table {
    background-color: $white;
    border-radius: $border-rad-s;

    .MuiDataGrid {
        &-root {
            font-family: 'Manrope';
            font-size: $text-xs;
            border: none;
        
            .MuiDataGrid-columnsContainer {
                font-size: $headline-6;

                .MuiDataGrid-columnHeaderTitleContainer {
                    padding: 0;
                }
            }

            .MuiDataGrid-windowContainer {

                    .MuiDataGrid-row {
                        &:nth-child(2n+1) {
                            background-color: $light_blue;
                            transition: $transition;
                        }

                        &.Mui-selected {
                            background-color: $main_edo--light;
                        }

                        &:hover {
                            background-color: $main_edo;
                            color: $white;
                        }

                        .MuiDataGrid-cell {
                            border: none;
                        }
                    }
            }

            .MuiButton-root {
                min-height: 48px;
                padding: $indent-sm $indent-m;
                text-transform: unset;
                font-size: $headline-6;
                font-weight: 700;

                .MuiButton-startIcon {
                    svg {
                        fill: $main_edo;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }

    &__cell {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            line-height: initial;
        }
    }
}