/* Breack point */
$smartphone: 767px;
$tablet: 1023px;
$desktop--s: 1439px;
$desktop--m: 1919px;
$desktop--l: 2559px;


/* Colors */
$black:             #072D57;
$white:             #FFFFFF;
$main_edo:          #6746EB;
$main_edo--light:   #F0EDFD;
$main_edo--darck:   #4E30C7;
$main_bg:           #F0F4F7;
$light_blue:        #E9F3FD;
$fontMain:          #303030;
$fontBlack900:      #072D57;
$fontBlack800:      #1D3F66;
$fontBlack700:      #557192;
$fontBlack600:      #778DA8;
$fontBlack500:      #99A9BA;
$fontBlack300:      #DDE2E8;
$fontBlack100:      #F0F4F7;
$success:           #00B54E;
$warning:           #F98700;
$error:             #F24646;
$brandFocus:        #8dcdff;


/* Colors Theme */
$themes: (
    light: (
        black:          $black,
        white:          $white,
        main_edo:       #4E30C7,
        fontMain:       $fontMain,
    ),
    dark: (
        black:          $white,
        white:          $black,
        main_edo:       #4E30C7,
        fontMain:       invert($fontMain),
    )
);

/* Font */
/* headline */
$headline-1:    58px;
$headline-2:    42px;
$headline-3:    36px;
$headline-4:    24px;
$headline-5:    18px;
$headline-6:    14px;
$headline-7:    12px;

/* text */
$text-s:    10px;
$text-xs:   12px;
$text-m:    14px;
$text-l:    18px;

/* Module size */
$module:    4px;

/* Indentation */
$indent-s:      $module;
$indent-xs:     calc(#{$module} * 2);
$indent-sm:     calc(#{$module} * 3);
$indent-m:      calc(#{$module} * 4);
$indent-xm:     calc(#{$module} * 5);
$indent-ml:     calc(#{$module} * 6);
$indent-l:      calc(#{$module} * 8);

/* Border radius */
$border-rad-s: $module;
$border-rad-m: calc(#{$module} * 2);
$border-rad-l: calc(#{$module} * 3);

/* Box shadow */
$box-shadow-xs-m: 0px $indent-xs $indent-m rgba(0, 0, 0, 0.1);

/* Transition */
$transition: all 0.250s cubic-bezier(0.02, 0.01, 0.47, 1);

/* Form header size */
$form_header_height: 64px;
