@import '~assets/styles/utils/mixin';

.toast {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    z-index: 5;

    &-messages {
        flex: 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: $indent-m;

        & > *:not(:only-child):not(:last-child) {
            margin-bottom: $indent-m;
        }
    }
}