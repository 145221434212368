@import '~assets/styles/utils/mixin';

.file-viewer {
    &__header {}

    &__body {
        display: flex;
        align-items: center;

        .files-list {
            display: flex;
            overflow: hidden;

            .file-chield {
                display: flex;
                align-items: center;
                overflow: hidden;

                & > *:not(:only-child):not(:first-child):not(:last-child) {
                    margin-right: $indent-sm;
                }

                &__name {
                    flex: 1 100%;
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &__size {
                    white-space: pre;
                }

                &__viewer, &__cleaner {
                    fill: $main_edo;
                    transition: $transition;
                    cursor: pointer;
        
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}

.file-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparentize($black, 0.8);
    
    .file-wrapper {
        width: 80%;
        height: 80%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        border-radius: $border-rad-s;
        box-shadow: 0px 4px 56px 0px #0000001A;
    
    }
    
    .loaded-file {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: $border-rad-s;
    }
    
    .close-icon {
        position: absolute;
        top: -40px;
        right: -40px;
        cursor: pointer;
    
        svg {
            width: 32px;
            height: 32px;
            padding: 0;
            fill: $white;
            transition: $transition;
    
            &:hover {
                fill: $main_edo;
                transform: scale(1.2);
            }
        }
    }
}