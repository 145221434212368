@import '~assets/styles/utils/mixin';

.progress-bar {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: transparentize($fontBlack900, 0.1);
    overflow: hidden;
    transition: $transition;
    z-index: 5;

    &__wrapper {
        min-width: 300px;
        display: flex;
        flex-direction: column;
        padding: $indent-m;
        background-color: $white;
        border-radius: $border-rad-s;
        transform: translateY(100vh);
        transition: all 1s;

        & > *:not(:only-child):not(:first-child) {
            margin-top: $indent-m;
        }
    }

    &__header {
        display: flex;
        justify-content: center;
    }

    &__body {
        display: flex;

        & > *:not(:only-child):not(:first-child) {
            margin-left: $indent-m;
        }

        &-decription {
            flex: 1 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            & > *:not(:only-child):not(:first-child) {
                margin-top: $indent-xs;
            }

            .progress-item {
                display: flex;
    
                & > *:not(:only-child):not(:first-child) {
                    margin-left: $indent-xs;
                }

                &__name {
                    &:after {
                        content: ':';
                    }
                }

                &__counter {
                    white-space: nowrap;
                }
            }
        }

        &-vizualization {
            display: flex;
            align-items: center;
        }
    }

    &--visiable {
        opacity: 1;

        .progress-bar__wrapper {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &--hidden {
        opacity: 0;
        transition-delay: 1s;
        pointer-events: none;

        .progress-bar__wrapper {
            transform: translateY(100vh);
            opacity: 0;
            transition-delay: 1s;
        }

    }
}