@import '~assets/styles/utils/mixin';

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    pointer-events: none;

    svg {
        width: 32px;
        height: 32px;
    }
}